@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/_colors';

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,800;1,200;1,400;1,800&display=swap');

body,
html {
  scroll-behavior: smooth;
  font-size: 0.95em;
  font-family: 'Nunito', sans-serif;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--assist-color_body);
  color: var(--assist-color_neutral-900);
}
body,
addin-root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 0%;
}

button {
  font-family: 'Nunito', sans-serif;
  display: inline-flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  background: var(--assist-color_primary-500);
  color: var(--assist-color_neutral-100);
  font-weight: 600;
  padding: 1em;
  transition: all 0.25s ease;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    background: var(--assist-color_primary-600);
    cue-icon {
      transform: scale(1.15);
    }
  }
  &.secondary {
    background: var(--assist-color_neutral-100);
    color: var(--assist-color_primary-500);
    cue-icon {
      --icon-color: var(--assist-color_primary-500);
    }
    &:hover {
      background: var(--assist-color_neutral-200);
    }
  }
  &.error {
    background: var(--assist-color_error-500);
    color: var(--assist-color_neutral-100);
    cue-icon {
      --icon-color: var(--assist-color_neutral-100);
    }
    &:hover {
      background: var(--assist-color_error-600);
    }
  }
  &.small {
    padding: 0.5em;
    cue-icon {
      --icon-size: 1em;
    }
  }
  &.link {
    background: transparent;
    padding: 0.5em;
    text-decoration: underline;
    color: var(--assist-color_primary-500);
    box-shadow: none;
    cue-icon {
      --icon-size: 1em;
      --icon-color: var(--assist-color_primary-500);
    }
    &:hover {
      background: var(--assist-color_primary-100);
    }
    &.secondary {
      color: var(--assist-color_neutral-900);
      cue-icon {
        --icon-color: var(--assist-color_neutral-900);
      }
      &:hover {
        background: var(--assist-color_neutral-200);
      }
    }
    &.error {
      color: var(--assist-color_error-500);
      cue-icon {
        --icon-color: var(--assist-color_error-500);
      }
      &:hover {
        background: var(--assist-color_error-100);
      }
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.15);
  }
  &:active {
    transition: all 0.1s ease;
    opacity: 0.7;
    transform: scale(0.95);
  }
  cue-icon {
    --icon-size: 1.125em;
    --icon-color: var(--assist-color_neutral-100);
  }
  &:disabled {
    opacity: 0.5;
    cursor: unset;
    pointer-events: none;
    background: var(--assist-color_neutral-300) !important;
    color: var(--assist-color_neutral-500) !important;
    cue-icon {
      --icon-color: var(--assist-color_neutral-500) !important;
    }
    &.link {
      background: transparent !important;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  margin: 0;
}

h1 {
  font-size: 1.725em;
  color: var(--assist-color_primary-500);
}

h2 {
  font-size: 1.25em;
  color: var(--assist-color_neutral-800);
}

h3 {
  font-size: 1.25em;
  color: var(--assist-color_neutral-800);
}

hr {
  height: 1px;
  width: 100%;
  background: var(--assist-color_neutral-200);
  margin: 1.5em 0;
  border: none;
  flex-shrink: 0;
}

.safe-padding {
  padding: 0 1em;
}

p-selectbutton {
  padding: 1px;
  border-radius: 10px;
  border: 1px solid var(--assist-color_primary-500);
  flex-shrink: 0;
  .p-button {
    padding: 0.325em 0.525em;
    border-radius: 8px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    &.p-highlight {
      background: var(--assist-color_primary-500) !important;
      color: var(--assist-color_neutral-100) !important;
    }
    .p-button-label {
      font-weight: 400;
      font-family: 'Nunito', sans-serif;
    }
  }
}

.mobile-ui {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  .slides {
    display: flex;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    flex-grow: 1;
  }

  .slide {
    scroll-snap-align: start;
    width: 100%;
    overflow-y: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    scroll-snap-stop: always;
  }

  .tabs-slider {
    display: block;
    position: absolute;
    bottom: 0;
    transition:
      transform 0.3s ease,
      width 0.3s ease;
    height: 3px;
    border-radius: 3px;
    z-index: 1;
    background-color: var(--assist-color_common_main);
  }

  .tabs-container {
    height: 2em;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      height: 3px;
      border-radius: 2px;
      background: var(--assist-color_neutral-200);
    }
  }

  .tabs {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    flex-shrink: 0;
    gap: 1em;
    position: relative;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      -webkit-box-shadow: none;
      border-radius: 0px;
      background-color: transparent;
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: transparent;
    }

    .tab-item {
      cursor: pointer;
      color: var(--assist-color_common_main);
      position: relative;
      text-align: center;
      flex-shrink: 0;
      flex-grow: 1;

      &.disabled {
        pointer-events: none;
        color: var(--assist-color_neutral-500);
        cursor: default;
      }
    }
  }
}

.item-tescted-text {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  .image-wrapper {
    display: inline-block;
    img {
      width: 60px;
      border-radius: 10px;
    }
  }
}
