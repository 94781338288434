:root {
  --assist-color_primary-900: #0e2033;
  --assist-color_primary-600: #1a324f;
  --assist-color_primary-500: #233c5a;
  --assist-color_primary-400: #3a506b;
  --assist-color_primary-100: #c3d2e4;

  --assist-color_common_lightgray: #f5f5f5;
  // STATE COLORS
  --assist-color_error-900: #3d0505;
  --assist-color_error-600: #a52626;
  --assist-color_error-500: #dc3333;
  --assist-color_error-400: #f34f4f;
  --assist-color_error-100: #eec2c2;
  --assist-color_warning-500: #f68b2d;
  --assist-color_success-900: #125505;
  --assist-color_success-600: #347428;
  --assist-color_success-500: #469b36;
  --assist-color_success-400: #6acc57;
  --assist-color_success-100: #c8efc1;

  // NEUTRAL COLORS
  --assist-color_body: #ffffff;
  --assist-color_neutral-100: #ffffff;
  --assist-color_neutral-200: #dedede;
  --assist-color_neutral-300: #c4c4c4;
  --assist-color_neutral-400: #a9a9a9;
  --assist-color_neutral-500: #898989;
  --assist-color_neutral-600: #5e5e5e;
  --assist-color_neutral-700: #3f3f3f;
  --assist-color_neutral-800: #1f1f1f;
  --assist-color_neutral-900: #000000;
}
